export const thumbnailClusterEntry = (sortKey: string = 'titles.i18n_string') => {
  return {
    fields: ['background_color'],
    populate: {
      ...queryFragmentCollectionTypeDefault,
      ...queryFragmentBlocks,
      cover_image: true,
      city_entries: {
        sort: [`${sortKey}:asc`],
        populate: {
          titles: true,
          slugs: true,
        }
      }
    }
  }
}
